import React, { useState, useEffect, useRef } from 'react';
import './Timeline.css'; // Import the CSS file
import backgroundImage from '../../assets/m2.jpg';

const timelineData = [
    { year: "Registration Open", description: "2023 November 25" },
    { year: "Registration Close", description: "2023 November 25" },
    { year: "Submission Open", description: "2023 November 25" },
    { year: "Submission Close", description: "2023 November 25" },
    { year: "Event Day", description: "2023 November 25" },
];

const Timeline = () => {
    const [fontSize, setFontSize] = useState('3.5rem');
    const timelineRefs = useRef([]);

    useEffect(() => {
        const handleResize = () => {
            setFontSize(window.innerWidth <= 678 ? '2.5rem' : '3.5rem');
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial font size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const isMobile = window.innerWidth <= 600;
                    entry.target.classList.add(isMobile ? 'slide-in-bottom' : 'slide-in'); // Add appropriate class
                } else {
                    entry.target.classList.remove('slide-in', 'slide-in-bottom'); // Remove class when out of view
                }
            });
        });

        timelineRefs.current.forEach(ref => {
            if (ref) observer.observe(ref); // Observe each timeline item
        });

        return () => {
            timelineRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref); // Cleanup observer on component unmount
            });
        };
    }, []);

    return (
        <section style={{ 
            backgroundImage: `url(${backgroundImage})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            padding: '5rem 0'
        }}>
            <div className="container">
                <h2 className="timeline-header" style={{ fontSize, textAlign: 'center', marginBottom: '2rem', fontFamily: 'Poppins, sans-serif' }}>Timeline</h2>
                <div className="main-timeline">
                    {timelineData.map((item, index) => (
                        <div className={`timeline ${index % 2 === 0 ? 'left' : 'right'}`} key={index} ref={el => timelineRefs.current[index] = el}>
                            <div className="card" style={{
                                backgroundColor: '#2a2e32',
                                borderRadius: '30px',
                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.2s',
                                border: '2px solid #ac834f',
                                fontFamily: 'Poppins, sans-serif'
                            }}>
                                <div className="card-body">
                                    <h3 className="timeline-year">{item.year}</h3>
                                    <p className="timeline-description mb-0">{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Timeline;
